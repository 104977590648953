import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/index';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class ResizeService {
  private readonly _resizeSubject = new Subject<number>();
  private readonly _resizeObservable = this._resizeSubject.asObservable().pipe(debounceTime(200));

  set resize(value: number) {
    this._resizeSubject.next(value);
  }

  get resizeObservable() {
    return this._resizeObservable;
  }
}
