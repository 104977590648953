import { Component, HostListener } from '@angular/core';
import { ResizeService } from './core/service/resize.service';

@Component({
  selector: 'meg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'meg-web';

  constructor(private _resizeService: ResizeService) {

  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number): void {
    this._resizeService.resize = width;
  }
}
